import React from 'react';
import { graphql } from 'gatsby';
import CTARedirectProvider from './CTARedirectProvider';

export function blockToComponent(
    blockKey: string | undefined,
    block: any,
    dataGALocation: string,
    sectionIndex: number
): JSX.Element {
    const blockMap: { [blockKey: string]: JSX.Element } = {
        block_content__cta_redirect: <CTARedirectProvider {...{ block, dataGALocation, sectionIndex }} />
    };
    if (blockKey === undefined || blockMap[blockKey] === undefined) {
        return (
            <div>
                <div style={{ color: 'red' }}>Failed to load block {blockKey} was not valid</div>
                <div style={{ color: 'red' }}>JSON stringify of blockData: {JSON.stringify(block)}</div>
            </div>
        );
    }
    return blockMap[blockKey];
}

export const query = graphql`
    fragment supportedBlocks on Node {
        internal {
            type
        }
        ... on block_content__cta_redirect {
            ...CTARedirectProvider
        }
    }
`;
