import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { ReactElement } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import Button from 'ui-kit/button/button';
import InfoAlert from 'ui-kit/info-alert/info-alert';

import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

import { accountSendChangePasswordRequestRoutine } from 'state/account/account.routines';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

import { getPhoneNumber } from 'util/globalVariables';

import './change-password-request.style.scss';

interface ChangePasswordProps {
    username: string;
}
const ChangePassword = ({ username }: ChangePasswordProps): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const showSendPasswordChangeSuccessModal = React.useCallback(() => {
        dispatch(
            openModal({
                bodyContent: (
                    <BirdiModalContent
                        body={t('pages.profile.security.changePasswordRequest.successModal.body')}
                        icon="default"
                        title={t('pages.profile.security.changePasswordRequest.successModal.title')}
                    />
                ),
                ctas: [
                    {
                        dataGALocation: 'PasswordChangeRequest',
                        label: t('pages.profile.security.changePasswordRequest.successModal.submit'),
                        onClick: () => {
                            dispatch(closeModal({}));
                            return false;
                        },
                        variant: 'primary'
                    }
                ],
                showClose: true
            })
        );
    }, [dispatch, t]);

    const showSendPasswordChangeErrorModal = React.useCallback(() => {
        dispatch(
            openModal({
                bodyContent: (
                    <BirdiModalContentAlt
                        subTitle={t('pages.profile.security.changePasswordRequest.failureModal.body')}
                    />
                ),
                ctas: [
                    {
                        dataGALocation: 'ForgotPasswordError',
                        label: t('pages.profile.security.changePasswordRequest.failureModal.submit'),
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        variant: 'primary'
                    }
                ],
                headerContent: (
                    <BirdiModalHeaderDanger
                        headerText={t('pages.profile.security.changePasswordRequest.failureModal.title')}
                        icon="alert"
                    />
                ),
                showClose: true,
                type: 'danger',
                size: 'lg'
            })
        );
    }, [dispatch, t]);

    const resetPassword = React.useCallback(() => {
        dispatch(
            accountSendChangePasswordRequestRoutine({
                username: username,
                onSuccess: () => {
                    showSendPasswordChangeSuccessModal();
                },
                onFailure: () => {
                    showSendPasswordChangeErrorModal();
                }
            })
        );
    }, [dispatch, showSendPasswordChangeErrorModal, showSendPasswordChangeSuccessModal, username]);

    return (
        <Container fluid className="change-password">
            <Row>
                <Col>{t('signIn.updatePassword.body')}</Col>
            </Row>
            <Row>
                <Col>
                    <InfoAlert
                        body={t('signIn.updatePassword.infoAlert', {
                            phoneNumber: getPhoneNumber({ isPlainText: true })
                        })}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="change-password__helper-text">
                        {t('signIn.updatePassword.emailNotReceived')}
                        <Button
                            label={t('signIn.updatePassword.emailNotReceivedLinkText')}
                            type="button"
                            variant="text-blue"
                            onClick={resetPassword}
                            dataGALocation="UpdatePasswordEmailNotReceived"
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default ChangePassword;
