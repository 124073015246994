import React from 'react';
import { graphql } from 'gatsby';
import { CTARedirectProps } from 'components/cta-redirect/cta-redirect.props';
import CTARedirect from 'components/cta-redirect/cta-redirect.component';

export default function CTARedirectProvider({
    block,
    dataGALocation,
    sectionIndex
}: {
    block: GatsbyTypes.CTARedirectProviderFragment;
    dataGALocation: string;
    sectionIndex: number;
}) {
    const ctaRedirectProps: CTARedirectProps = {
        bodyText: block.body?.processed,
        highlightText: block.field_block_highlight?.processed,
        label: block.field_block_label,
        links: block.field_cta_link,
        linkStyle: block.field_link_style,
        sectionIndex: sectionIndex,
        dataGALocation: dataGALocation
    };
    return <CTARedirect {...ctaRedirectProps} />;
}

export const query = graphql`
    fragment CTARedirectProvider on block_content__cta_redirect {
        body {
            processed
        }
        field_block_label
        field_cta_link {
            title
            uri
        }
        field_link_style
        internal {
            type
        }
    }
`;
