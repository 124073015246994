import React, { ReactElement } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import './footer-note.style.scss';

interface FooterNoteProps {
    body: string;
}
const FooterNote = ({ body }: FooterNoteProps): ReactElement => {
    return (
        <Container fluid className="footer-note">
            <Row>
                <Col xs={12} className="text-center">
                    {body}
                </Col>
            </Row>
        </Container>
    );
};

export default FooterNote;
