import React, { ReactElement } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import sanitizeHtml from 'sanitize-html';
import Link from 'ui-kit/link/link';
import { CTARedirectProps, CTALinkProps } from './cta-redirect.props';

const cleanUri = (uri: string): string => (uri.includes('internal:') ? uri.replace('internal:', '') : uri);

const CTARedirect = ({
    bodyText,
    highlightText,
    label,
    links,
    linkStyle,
    sectionIndex,
    dataGALocation
}: CTARedirectProps): ReactElement => {
    // Set the linkStyle as a variant, if the linkStyle does not include a 'btn' value
    const variant = linkStyle?.includes('btn') ? '' : linkStyle?.includes('arrow') ? 'arrow' : linkStyle;
    const linkClass = linkStyle?.includes('btn') ? 'btn-wrapper' : linkStyle?.includes('arrow') ? 'd-inline-block' : '';
    const isVertical = linkStyle?.includes('vertical');

    return (
        <Container
            data-ga-index={`${sectionIndex > -1 ? 1 + sectionIndex : 0}`}
            data-ga-location={dataGALocation}
            fluid
            className="cta-redirect"
        >
            {bodyText && (
                <div
                    className="body-container"
                    dangerouslySetInnerHTML={{
                        __html: sanitizeHtml(bodyText, {
                            allowedAttributes: {
                                a: ['id', 'href', 'name', 'rel', 'target', 'data-*'],
                                img: ['src', 'alt'],
                                '*': ['class']
                            }
                        })
                    }}
                />
            )}
            <div className="text-center lead my-4">{label}</div>

            <Row
                xs={isVertical ? undefined : 1}
                lg={isVertical ? undefined : links.length}
                className={'mb-3' + (isVertical ? 'd-flex align-items-center flex-column' : '')}
            >
                {links.map((link: CTALinkProps, index: number) => {
                    return (
                        <Col className={'mb-4 mb-lg-0 text-center'} key={`link_${index}`}>
                            <Link
                                to={cleanUri(link.uri)}
                                label={link.title}
                                className={linkClass}
                                anchorClassName={linkStyle}
                                variant={variant}
                                dataGALocation={dataGALocation}
                                external={false}
                            />
                        </Col>
                    );
                })}
            </Row>
            {highlightText && (
                <div className="highlight-text-container">
                    <div
                        className="highlight-text"
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(highlightText, {
                                allowedAttributes: {
                                    a: ['id', 'href', 'name', 'rel', 'target', 'data-*'],
                                    img: ['src', 'alt'],
                                    '*': ['class']
                                }
                            })
                        }}
                    />
                </div>
            )}
        </Container>
    );
};

export default CTARedirect;
