import { ReactElement, useCallback } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, Row, Col } from 'react-bootstrap';

import { EMAIL_UPDATE_SCHEMA } from 'schema/login.schema';

import Button from 'ui-kit/button/button';
import Text from 'ui-kit/text/text';
import InfoAlert from 'ui-kit/info-alert/info-alert';

import './change-email-form.style.scss';

export interface ChangeEmailFormProps {
    isLoading: boolean;
    onSubmit: (email: string | undefined) => void;
}

const ChangeEmailForm = ({ isLoading, onSubmit }: ChangeEmailFormProps): ReactElement => {
    const { t } = useTranslation();
    const formName = 'SignInUpdateEmailForm';

    const handleSubmit = useCallback(
        (values: Partial<{ email: string }>) => {
            onSubmit(values.email);
        },
        [onSubmit]
    );
    return (
        <Container fluid className="change-email">
            <Row>
                <Col>{t('signIn.updateEmail.body')}</Col>
            </Row>
            <Row className="change-email__form">
                <Col xs={12}>
                    <InfoAlert body={t('signIn.updateEmail.infoAlert')} />
                </Col>
                <Col xs={12}>
                    <Formik<Partial<{ email: string }>>
                        onSubmit={handleSubmit}
                        validationSchema={EMAIL_UPDATE_SCHEMA}
                        initialValues={{
                            email: ''
                        }}
                    >
                        {(formik: any) => (
                            <Form
                                id="update-email-form"
                                data-ga-form-name={formName}
                                onSubmit={formik.handleSubmit}
                                autoComplete="off"
                            >
                                <Text
                                    label={t('signIn.updateEmail.emailFieldLabel')}
                                    name="email"
                                    maxLength={255}
                                    onChange={formik.handleChange}
                                    errors={formik.errors?.email ? t('signIn.updateEmail.emailRequired') : undefined}
                                    touched={formik.touched.email}
                                    value={formik.values?.email}
                                />

                                <div className="d-flex flex-column align-items-center">
                                    <Button
                                        async
                                        className="sm-full"
                                        label={t('signIn.updateEmail.submit')}
                                        type="submit"
                                        isBusy={isLoading}
                                        dataGAFormName={formName}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    );
};

export default ChangeEmailForm;
