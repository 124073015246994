import React, { ReactElement } from 'react';
import CircleInfo from 'ui-kit/icons/info/circle-info-icon';
import './info-alert.style.scss';

interface InfoAlertProps {
    body: string;
}
const InfoAlert = ({ body }: InfoAlertProps): ReactElement => {
    return (
        <div className="info-alert">
            <div className="text-center">
                <CircleInfo className="info-alert__icon" />
            </div>
            <div className="info-alert__body">{body}</div>
        </div>
    );
};

export default InfoAlert;
